@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

* {
    font-family: 'Sora', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

.container-style {
    height: 100vh;
}

#tsparticles {
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 0;
}